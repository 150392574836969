import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import InputText from 'src/components/UI/InputText/InputText'
import TextArea from 'src/components/UI/TextArea/TextArea'
import Markdown from 'src/components/Markdown/Markdown'
import { ArrowRightIcon } from 'src/components/UI/Elements/Icon'

import * as styles from './ContactForm.module.scss'
import { Props } from './types'
import { contactSchema } from './schemas'

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = ({ blok }: Props) => {
  const { text, id } = blok

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const resolver = yupResolver(contactSchema)
  const {
    getValues,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: resolver,
  })

  const onSubmit = (data: any) => {
    if (data) {
      const body = encode({ 'form-name': id, ...data })

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: body,
      })
        .then(() => {
          setSuccess(true)
          console.log('Success!')
          setTimeout(() => {
            setSuccess(false)
            reset()
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return id ? (
    <SbEditable content={blok}>
      <Flex
        className={styles.form}
        flexDirection="column"
        width={[1, null, null, 1]}
      >
        {text && (
          <Box
            className={styles.text}
            marginBottom={[6, null, null, 12]}
            paddingX={[5, null, null, 5]}
          >
            <Markdown>{text}</Markdown>
          </Box>
        )}
        <Flex
          as="form"
          name={id}
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          flexDirection="column"
          onSubmit={handleSubmit(onSubmit)}
          className={styles.form}
          height="100%"
          novalidate
        >
          <Box paddingX={[5, null, null, 5]}>
            <input type="hidden" name="form-name" value={id} />
            <Flex width={1} marginBottom={8}>
              <Box width={1} marginRight={8}>
                <InputText
                  name="sureName"
                  placeholder="Förnamn"
                  type="text"
                  register={register}
                  error={errors.sureName}
                  required
                />
              </Box>
              <Box width={1}>
                <InputText
                  name="lastName"
                  placeholder="Efternamn"
                  type="text"
                  register={register}
                  error={errors.sureName}
                  required
                />
              </Box>
            </Flex>
            <Box marginBottom={8}>
              <InputText
                name="phone"
                placeholder="Telefonnummer"
                type="tel"
                register={register}
                error={errors.phone}
                required
              />
            </Box>
            <Box marginBottom={8}>
              <InputText
                name="email"
                placeholder="E-post"
                type="email"
                register={register}
                error={errors.email}
                required
              />
            </Box>
            <Box marginBottom={[8, null, null, 20]} flex="2" height="100%">
              <TextArea
                name="message"
                placeholder="Meddelande"
                error={errors.message}
                register={register}
              />
            </Box>
          </Box>

          <Box as="button" className={styles.submit} type="submit">
            <Flex
              height="100%"
              justifyContent="space-between"
              alignItems="center"
              width={1}
            >
              <Box as="span">
                {error
                  ? 'Försök igen'
                  : success
                  ? 'Skickat'
                  : loading
                  ? 'Skickar'
                  : 'Skicka in formulär'}
              </Box>
              <ArrowRightIcon width={32} height={32} color="#FFFFFF" />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </SbEditable>
  ) : null
}

export default ContactForm
