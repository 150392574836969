export const getColor = (color: string) => {
  switch (color) {
    case 'purple':
      return '#ABB5C6'
    case 'peach':
      return '#EBC1A9'
    case 'turqoise':
      return '#AFC7BA'
    default:
      return '#AFC7BA'
  }
}
