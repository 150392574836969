import React, { useEffect, useRef } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Heading from 'src/components/Heading/Heading'
import { getColor } from 'src/helpers/getColor'
import Components from 'src/storyblok/Components'

import * as styles from './TextWithInfoList.module.scss'
import { Props } from './types'

const TextWithInfoList = ({ blok }: any) => {
  const { title, preamble, background, real_estates, anchor } = blok

  const color = getColor(background)

  return (
    <SbEditable content={blok}>
      <section textcolor="black" bgcolor={color}>
        <div id={anchor} style={{ position: 'relative', top: '-64px' }} />
        <Flex
          minHeight="100vh"
          paddingTop={[32, null, null, 18]}
          paddingBottom={[10, null, null, 20]}
          paddingX={[7, null, null, 16]}
          flexDirection="column"
        >
          <Box>
            <Heading title={title} preamble={preamble} />
          </Box>
          <Flex
            marginX={['-20px']}
            marginTop={[12, null, null, 33]}
            flexWrap="wrap"
            width={'auto'}
          >
            {real_estates &&
              real_estates.map((blok: any) => {
                const { form } = blok || {}
                const { id = '' } = form && form.length > 0 ? form[0] : {}
                return (
                  <Box
                    key={blok._uid}
                    marginBottom={[8, null, null, 12]}
                    width={[1, null, null, id ? 1 : 1 / 2]}
                    paddingX={[5]}
                  >
                    {React.createElement(Components(blok.component), {
                      key: blok._uid,
                      blok: blok,
                    })}
                  </Box>
                )
              })}
          </Flex>
        </Flex>
      </section>
    </SbEditable>
  )
}

export default TextWithInfoList
