import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import * as styles from './InfoRow.module.scss'

const InfoRow = ({ blok }: any) => {
  const { label, value } = blok

  return (
    <SbEditable content={blok}>
      <Flex alignItems="flex-start" className={styles.row} paddingY={2}>
        <Box width={[1 / 2, null, null, 2 / 6]}>{label}</Box>
        <Box width={[1 / 2, null, null, 4 / 6]}>{value}</Box>
      </Flex>
    </SbEditable>
  )
}

export default InfoRow
