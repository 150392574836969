import React from 'react'

type Icon = {
  color?: string
  className?: string
  width?: number
  height?: number
  strokeWidth?: number
}

export const ArrowRightIcon = ({
  color = 'black',
  className,
  width,
  height,
}: Icon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    viewBox="0 0 257.21 233.09"
    width={width}
    height={height}
    className={className}
  >
    <path
      fill={color}
      d="M114.6 26.23l80.83 80.83H25.46v18.97h169.97l-80.83 80.83 13.42 13.42 103.73-103.74L128.02 12.81z"
    />
  </svg>
)
