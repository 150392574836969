import React from 'react'
import { Box, Flex } from 'reflexbox'

import * as styles from './Heading.module.scss'
import { Props } from './types'

const Heading = ({ title, preamble }: Props) => (
  <Flex width={[1, null, null, 7 / 12]} flexDirection="column">
    <Box marginBottom={[8, null, null, 14]} className={styles.title} as="h2">
      {title}
    </Box>
    <Box as="p" className={styles.description}>
      {preamble}
    </Box>
  </Flex>
)

export default Heading
