import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'

import * as styles from './RealEstate.module.scss'

const RealEstate = ({ blok }: any) => {
  const { image, title, description, table, form } = blok || {}
  const formData = form && form.length > 0 ? form[0] : {}

  return (
    <SbEditable content={blok}>
      <Box width={1} className={styles.wrapper}>
        <Image ratio={16 / 9} src={image.filename} size={'50vw'} alt={title} />
        <div>
          <Flex flexWrap={['wrap', null, null, 'nowrap']}>
            <Box
              width={[1, null, null, formData?.id ? 1 / 2 : 1]}
              paddingY={[10, null, null, 12]}
              paddingX={[5, null, null, 5]}
              className={styles.content}
            >
              <Box className={styles.title} as="h3">
                {title}
              </Box>
              <Box
                marginTop={[8, null, null, 12]}
                className={styles.description}
                as="p"
              >
                {description}
              </Box>

              <div>
                <Flex
                  marginTop={[12]}
                  flexDirection="column"
                  width={1}
                  className={styles.list}
                >
                  {table &&
                    table.map((blok: any) =>
                      React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                      })
                    )}
                </Flex>
              </div>
            </Box>
            {formData?.id && (
              <Box
                width={[1, null, null, 1 / 2]}
                paddingTop={[8, null, null, 32]}
                className={styles.formData}
              >
                {form &&
                  form.map((blok: any) =>
                    React.createElement(Components(blok.component), {
                      key: blok._uid,
                      blok: blok,
                    })
                  )}
              </Box>
            )}
          </Flex>
        </div>
      </Box>
    </SbEditable>
  )
}

export default RealEstate
