import React from 'react'
import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import SbEditable from 'storyblok-react'
import * as styles from './Hero.module.scss'
import { Props } from './types'
import scrollIntoView from 'scroll-into-view'

const Hero = ({ blok, title, cta, image, anchor }: Props) => {
  return (
    <SbEditable content={blok}>
      <Box id="hero" className={styles.wrapper} width={1}>
        <Flex
          paddingBottom={[16, null, null, 32]}
          paddingX={[7, null, null, 16]}
          className={styles.content}
          width={1}
          height="100%"
          alignItems="center"
        >
          <Flex
            marginLeft={[0, null, null, `${(1 / 12) * 100}%`]}
            width={[1, null, null, 7 / 12]}
            height="100%"
            justifyContent={['flex-end', null, null, 'space-between']}
            alignItems="flex-start"
            flexDirection="column"
          >
            {cta && <div />}
            <Box marginBottom={['20vh', null, null, 0]} as="h1">
              {title}
            </Box>
            {cta && (
              <Box
                className={styles.button}
                onClick={() => {
                  if (anchor) {
                    const el = document.getElementById(anchor)
                    if (el) {
                      scrollIntoView(el, {
                        time: 2000,
                        align: {
                          top: 0,
                          left: 0,
                        },
                      })
                    }
                  }
                }}
                as="button"
              >
                {cta}
              </Box>
            )}
          </Flex>
        </Flex>
        <Image src={image} size="100vw" alt="title" />
      </Box>
    </SbEditable>
  )
}

export default Hero
