import React, { useEffect, useRef } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Heading from 'src/components/Heading/Heading'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import { getColor } from 'src/helpers/getColor'
import Components from 'src/storyblok/Components'

import * as styles from './TextWithInfoList.module.scss'
import { Props } from './types'

const ImageAndText = ({ blok }: any) => {
  const { title, image, preamble, background, text, anchor } = blok
  const color = getColor(background)

  return (
    <SbEditable content={blok}>
      <section textcolor="black" bgcolor={color}>
        <Flex
          minHeight="100vh"
          paddingTop={[10, null, null, 18]}
          paddingBottom={[8, null, null, 20]}
          paddingX={[7, null, null, 16]}
          flexDirection="column"
        >
          <Box width={1} mb={[13, null, null, 16]}>
            <Image
              src={image.filename}
              ratio={16 / 9}
              alt={title}
              size="100vw"
            />
          </Box>
          <Flex width={1} flexDirection="column" minHeight="50vh">
            <Box>
              <div
                id={anchor}
                style={{ position: 'relative', top: '-128px' }}
              />
              <Heading title={title} preamble={preamble} />
            </Box>
            <Flex
              marginTop={[8, null, null, 12]}
              justifyContent="flex-end"
              width={1}
              paddingRight={`${(1 / 12) * 100}%`}
            >
              <Box width={[1, null, null, 7 / 12]}>
                <Markdown>{text}</Markdown>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </section>
    </SbEditable>
  )
}

export default ImageAndText
